@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('assets/scss/variable.scss');
@import url('assets/scss/typo.scss');
@import url('assets/scss/form.scss');
@import url('assets/scss/table.scss');
@import url('assets/scss/quill.scss');

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
	overflow-x: hidden;
	color: #212529;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    background-color: #f5f1ff;
}

.whakup {
    
    .whakup-container {
        display: flex;

        .whakup-content {
            width: 100%;

            .whakup-content-container {
                padding-top:90px;
                padding-left: 130px;
                padding-right: 30px;
                margin-bottom:50px;

                .isTrial {
                    padding-top:30px;
                }

                .background-white {
                    border-radius: 14px;
                    background: #FFF;
                    //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    padding: 20px;
                }
            }
        }

        .mobile-container {
            margin: 0 auto;
            margin-top:30vh;
            padding: 0 30px;
            h1 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 20px;
                text-align: center;
            }

            p {
                font-size: 16px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }

}

.disable-btn {
    opacity: 0.5;
}

@media screen and (max-width: 938px) {
    .whakup {
        .whakup-container {
            .whakup-content {
                .whakup-content-container {
                    padding-left: 130px;
                    padding-right: 20px;
                }
            }
        }
    }
    
}
