.campaign {

    .not-premium {
        filter: blur(3px);
    }

    .icon-premium {
        margin-right:5px;
    }

    .background-white-title {
        display: flex;
        justify-content: space-between;;

        h1 {
            color: #2B272D;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .actions {
            display: flex;
            align-items: center;

            .btn-add {
                border-radius: 50px;
                background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
                color: white;
                border: none;
                padding: 10px 20px;
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;
                text-decoration: none;
            }
        }
    }

}

.create-campaign {
    display: flex;
    flex-direction: row;
    flex: 12;
    
    .container {
        flex:7;
        margin-right: 30px;

        .title-page {
            border-radius: 16px;
            background: #FFF;
            padding: 20px;
            margin: 20px 0 30px 0;

            h1 {
                color: #2B272D;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            p {
                color: #8C888E;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        form {
            margin-top: 30px;
        }

        .list-btn {
            display: flex;
            justify-content: flex-start;

            button {
                margin-right: 30px;
                text-transform: capitalize;
                width: auto;
                background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
                border-radius: 50px;
                padding: 10px 25px;
            }

            .btn-secondary {
                background-color:transparent;
                border:none;
                background: var(--linear-test, linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                
                &:hover {
                    background-color: var(--primary);
                    color:white;
                }
            }
        }

        .infoFees {
            font-size:13px;

            a {
                color: black;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .icon-premium {
            margin-right: 10px;
        }
    }

    .aside-right {
        flex: 5;
        min-width: 350px;

        .apercu {
            background-color: white;
            border-radius: 16px;
            padding: 10px;
            margin-bottom: 15px;
            max-width: 450px;

            h2 {
                color: #2B272D;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            p {
                color: #8C888E;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}