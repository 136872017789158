.info {
    border-radius: 12px;
    padding:36px;
    margin-bottom:30px;
    background: #FFF;

    h2 {
        color: #2B272D;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    p {
        color: #2B272D;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top:15px;
    }

    div {
        border-radius: 50px;
        border: 1px solid #311077;
        padding:10px 20px;
        cursor: pointer;
        color: #311077;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align:center;
        margin-top:50px;
    }

    a {
        text-decoration: none;
        margin-top:50px;
        div {
            border-radius: 50px;
            border: 1px solid#311077;
            padding:10px 20px;
            color: #311077;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align:center;
        }
    }

}