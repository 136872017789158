form {

    .MuiButton-root { 
        border-radius: 50px;
        background: linear-gradient(90deg, rgba(189, 45, 105, 0.77) 0%, rgba(115, 30, 112, 0.77) 53%, rgba(49, 16, 119, 0.77) 100%);
        height:36px;
        width: auto;
    }

    .form-group {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }

    .input-phone {
        width: 100%;
        padding-right: 15px;

        .react-tel-input {
            input {
                height:40px;
            }
        }
    }
    

    .MuiInputBase-root {
        border-radius: 6px;
        //border: 1px solid rgba(49, 16, 119, 0.15);
        background: #FFF;
        height:42px;
        color: #2B272D;
        font-size: 16px;
        font-weight: 500;
    }

    .MuiFormControl-root {
        margin-right: 15px;
    }

    .MuiAutocomplete-fullWidth {
        margin-right: 15px;
    }

    .textarea {
        width: 100%;
        resize: none;

        label {
            font-size: 15px;
            font-weight: 600;
        }

        .ck-toolbar {
            border-top-left-radius: 6px !important;
            border-top-right-radius: 6px !important;
        }

        .ck.ck-editor {
            width: 100%;
            margin-top: 10px;
        }

        .error .ck.ck-editor {
            border: 1px solid red;
        }

        .ck-blurred, .ck-content.ck-editor__editable_inline {
            min-height: 200px;
            max-height: 350px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

}

 /* The switch - the box around the slider */
 .switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 23px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: var(--primary);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--primary);
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}