.dashboard {

    h1 {
        color: #2B272D;
        font-family: Poppins;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .contents {
        display: flex;
        flex-direction: row;
        flex:12;
        margin-top:30px;
    
        .aside-right {
            flex:3;
        }
    }
}

@media screen and (max-width: 938px) {
    .dashboard {
        .contents {
            flex-direction: column;
        }
    }
}