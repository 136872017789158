.personal-info {

    .personal-info-content {
        background-color: white;
        border-radius: 16px;
        padding: 24px 36px;
        margin-top:30px;
        max-width: 800px;
        margin-left:30px;

        h3 {
            font-size: 20px;
            font-weight: 500;
            color: #333;
            margin-bottom: 20px;
        }

        .profile-picture {
            position: relative;

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-bottom: 10px;
                text-align: center;
                margin: 0 auto;
            }

            .upload {
                label {
                    color: var(--primary);
                    cursor: pointer;
                    text-align: center;
                    position: absolute;
                    top: 0px;
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                }

                input {
                    display: none;
                }
            }
        }


        form {
            margin-top:30px;

            .MuiFormControl-root {
                margin-right:15px;
                max-width: 505px;
            }

            .form-group {
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;

                svg {
                    font-size:14px;
                }
            }

        }

        .btn-submit {
            background-color: var(--primary);
        }
    }
}