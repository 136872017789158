.contact {
    flex:9;
    margin-left:30px;

    .MuiDataGrid-cell {
        display: flex;
        align-items: center;
    }

    h1 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .list-action-group {
        display: flex;
        margin-bottom: 10px;

        .list-action {
            display: flex;

            .action {
                text-align: center;
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.3s ease;
                background-color: #f4f4f4;
                margin-right: 10px;
                font-size: 15px;

                svg {
                    margin-right: 10px;
                }
    
                &:hover {
                    background-color: #f4f4f4;
                }
            }

            .add {
                background-color: var(--primary);
                color: white;
                &:hover {
                    background-color: #48a53b;
                }
            }

            .delete {
                background-color: #ff4d4f;
                color: white;
                &:hover {
                    background-color: #e43e3f;
                }
            }


        }


    }

    .list-group-contact {
        display: flex;
        flex-wrap: wrap;

        .groups-item {
            background-color: #09803d;
            color:white;
            padding: 0px 10px;
            border-radius: 40px;
            font-size: 13px;
            margin-right: 5px;
            margin-bottom: 5px;
            height:22px;
            line-height: 23px;  
        }
    }


}