.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    
    .MuiDataGrid-columnHeaders {
        background-color: #f9fafc;
    }

    .MuiDataGrid-columnHeaderTitle {
        font-weight: 600;
        font-size: 0.75rem;
        letter-spacing: 0.17px;
        text-transform: uppercase;
        color: rgba(58, 53, 65, 0.87); 
    }

    .MuiDataGrid-columnHeaderTitleContainerContent {
        font-weight: 600;
        font-size: 0.75rem;
        letter-spacing: 0.17px;
        text-transform: uppercase;
        color: rgba(58, 53, 65, 0.87); 
    }

    .MuiDataGrid-cellContent {
        font-weight: 400;
        font-size: 15px;
        line-height: 1.5;
        color: rgba(58, 53, 65, 0.6);
    }

    .MuiDataGrid-row {
        border-bottom: 1px solid #e1e4e8;
        background-color: #ffffff;
    }

    .MuiDataGrid-footerContainer {
        background-color: white;
    }

    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        max-width: 150px;
        max-height: 30px;
        margin-top: 10px;
    }

    .approved {
        background-color: #4caf50;
    }

    .pending {
        background-color: #ff9800;
    }

    .rejected {
        background-color: #f44336;
    }

    .draft {
        background-color: #aaa;
    }

}