.stepper {
    margin-top:30px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 800px;
    min-width: 800px;
}

.MuiDialogContent-root {

    .selectFile {
        min-height: 300px;
        display: flex;
        align-items: center;

        p {
            margin: 0;
            padding: 0;
            font-size: 15px;
            font-weight: 500;
            color: #000;

            span {
                color: rgb(161, 70, 70);
                font-weight:bold;
            }
        }
    }

    .mappingFile {
        border-top:1px solid #ccc;

        p {
            margin: 15px 0px;
            padding: 0;
            font-size: 15px;
            font-weight: 500;
            color: #000;
        }

        .error {
            color: red;
            font-size: 14px;
            font-weight: 500;
        }

        .addCustomField {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;
            margin-top:30px;
    
            input {
                background-color:white;
                height:33px;
                border: 1px solid #ccc;
                border-radius: 6px;
                padding: 0 10px;
                font-size: 14px;
                font-weight: 500;
                color: #000;
            }

            button {
                background: none;
                border:none;
                color:#3f51b5;
                font-size: 13px;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            flex:12;

            label {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                margin-right: 15px;
                flex:4;
            }
            
            select {
                border-radius: 30px;
                background-color:white;
                height:33px;
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0 10px;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                flex:8;
            }
        }

        .addBtn {
            margin-top:30px;
            background: var(--primary);
            border:1px solid var(--primary);
            color:#ffffff;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 6px;
            transition:0.2s;
            float:right;

            &:hover {
                text-decoration: none;
                color:var(--primary);
                background: none;
                border:1px solid var(--primary);
                transition:0.2s;
            }
            
        }

        .mappingGroup {
            label {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                margin-right: 15px;
                flex:4;
            }

            select {
                border-radius: 30px;
                background-color:white;
                height:100px;
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0 10px;
                font-size: 14px;
                font-weight: 500;
                color: #000;
                flex:8;
            }
        }

    }
}